(function($) {
  //ScrollMagic init for gallery
  var controller = new ScrollMagic.Controller();
  // Lives Changed gallery
  new ScrollMagic.Scene({
    triggerElement: '.successstory-list',
    triggerHook: 1,
  })
    .setClassToggle('.successstory-list', 'initialized')
    .addTo(controller);
})(jQuery);

(function($) {
  var gallery = {
    wrapper: document.querySelector('.successstory-list__wrapper'),
    header: undefined,
    el: undefined,
    tiles: undefined,
    scrollInterval: undefined,
    scrollSpeed: 0,
    scrollDir: 0,

    triggerGalleryScroll: function(on) {
      if (on) {
        var ctrl = this;
        this.scrollInterval = setInterval(function() {
          if (ctrl.scrollDir && ctrl.scrollSpeed) {
            ctrl.el.scrollLeft += ctrl.scrollDir * ctrl.scrollSpeed;
            ctrl.header.style.transform =
              'translateX(' + -1 * ctrl.el.scrollLeft + 'px)';
          }
        }, 10);
      } else {
        this.scrollDir = 0;
        this.scrollSpeed = 0;
        clearInterval(this.scrollInterval);
      }
    },
    init: function() {
      if (this.wrapper) {
        this.header = this.wrapper.querySelector('.successstory-list__header');
        this.el = this.wrapper.querySelector('.successstory-list');
        this.tiles = Array.from(
          this.el.querySelectorAll('.block-successstory')
        );
        if (this.tiles.length > 2) {
          var ctrl = this;
          ctrl.el.addEventListener('mousemove', function(e) {
            var ePos = e.clientX / window.innerWidth - 0.5;
            var eDist = Math.abs(ePos);
            if (eDist < 0.3) {
              ctrl.scrollDir = 0;
              ctrl.scrollSpeed = 0;
            } else {
              ctrl.scrollSpeed = 50 * (eDist - 0.2);
              if (this.scrollLeft > 0 && ePos < 0) {
                ctrl.scrollDir = -1;
              } else if (
                this.scrollLeft + window.innerWidth < this.scrollWidth &&
                ePos > 0
              ) {
                ctrl.scrollDir = 1;
              } else {
                ctrl.scrollDir = 0;
              }
            }
          });
          ctrl.el.addEventListener('mouseenter', function(e) {
            ctrl.triggerGalleryScroll(true);
          });
          ctrl.el.addEventListener('mouseleave', function(e) {
            ctrl.triggerGalleryScroll(false);
          });
          ctrl.el.addEventListener('scroll', function(e) {
            ctrl.tiles.forEach((el, e) => {
              var tileOffset = (e + 0.5) * el.clientWidth;
              var translate =
                tileOffset < ctrl.el.scrollLeft
                  ? ctrl.el.scrollLeft - tileOffset
                  : 0;
              el.style.transform = 'translateX(-' + translate + 'px)';
            });
            ctrl.header.style.transform =
              'translateX(' + -1 * ctrl.el.scrollLeft + 'px)';
          });
        }
      }
      return this;
    },
  }.init();
})(jQuery);
